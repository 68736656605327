<template>
  <div class="openlevel">
    <van-nav-bar
      :title="$t('OPENVIP')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <van-tabs color="#00BFFF" v-model="active" animated>
      <van-tab :title="$t('VIP')">
        <div class="container">
          <div class="levelTitle">{{$t('VIP')}}</div>
          <van-grid center :column-num="3" :gutter="10">
            <van-grid-item class="backColor conter" >
              <div slot="text">
                <div class="title">
                  {{$t('OPENVVIP')}}
                </div>
                <div class="statu">
                  350₱
                </div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </van-tab>
    </van-tabs>
    <!-- <van-cell class="margtop">
      <div slot="title" class="levelMoney">会员</div>
      <div class="money" slot="default">₱0</div>
    </van-cell> -->
    <div class="subbox">
      <van-button @click="sub" class="sub" color="#00BFFF" type="primary" block>{{$t('SUBMIT')}}</van-button>
    </div>
  </div>
</template>

<script>
import { vip } from '@/api/my'
import './css/my.css'
import { Toast } from 'vant'
export default {
  name: 'openlevel',
  data () {
    return {
      active: 0
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    async sub () {
      const { data } = await vip()
      console.log(123)
      if (data.code === 200) {
        Toast(this.$t('SUBMITSUCCEED'))
      }
      if (data.code === 500) {
        Toast(data.message)
      }
      if (data.code === 1001) {
        Toast(data.message)
        setTimeout(() => {
          this.$router.push({
            name: 'rechargep'
          })
        }, 2000)
      }
    }
  }
}
</script>

<style scoped>
.conter {
  margin: 0 auto;
}
.container{
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 20px;
  width: 350px;
  padding-top: 10px;
  box-sizing: border-box;
  height: 250px;
  background-color: #fff;
}
.levelTitle {
  font-weight: 700;
  margin-top: 3px;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}
.title,
.statu {
  text-align: center;
}
.title {
  font-size: 18px;
}
.statu {
  font-size: 16px;
}
.levelMoney {
  font-size: 16px;
}
.money {
  font-size: 16px;
}
.margtop {
  margin-top: 20px;
}
.subbox {
  padding: 20px;
}
.sub {
  font-size: 18px;
}
</style>
